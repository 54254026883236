.recommandations_container {
    max-width: 700px;
    margin: auto;
}

.recommandation_item {
    text-align: center;
    padding: 0 1.25rem 1.25rem;
}

.thumb {
    height: 6.6rem;
    width: 5.6rem;
    margin: 0 auto;
}

.recommantion-avatar {
    border-radius: 50%;
}

.recommandions_title {
    margin-top: 0.75rem;
}

.subtitle {
    color: #8b88b1;
    font-size: var(--small-font-size);
}

.comment {
    background-color: var(--container-color);
    padding: 3.875rem;
    margin-top: 1.5rem;
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
    /* height: 15rem;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth; */
}

/* .comment::-webkit-scrollbar {
    width: 10px;
} */

.comment::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid var(--container-color);
    position: absolute;
    left: 49.5%;
    top: -0.625rem;
    transform: translateX(-7.5px);
}


.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 0.2rem !important;
}

.swiper-pagination-bullet-active {
    width: 1.2rem !important;
    height: 0.375rem !important;
    border-radius: 0.5rem !important;
    background-color: var(--third-color) !important;
}

/* .swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-prev {} */

/* 
.swiper-button-prev {
    left: -10px !important;
}

.swiper-button-next {
    right: -10px !important;
} */

.recommandation_item {
    cursor: grab;
    height: 10rem;
}


.swiper,
.swiper-wrapper {
    z-index: 0 !important;
}