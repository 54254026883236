.home {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url(../../assets/316.jpg); */
}

/* TODO */
/* .home::before {
    content: '';
    background: url(../../assets/316.jpg);
    height: 100%;
    width: 100%;
    position: absolute;

    z-index: -1;
} */

.intro {
    max-width: 540px;
    text-align: center;
    margin-bottom: 6rem;
}

/* TODO */
.home_img {
    height: 20rem;
    width: auto;
    margin-bottom: 1.5rem;
    /* filter: invert(100%); */
}

.home_name {
    font-size: var(--h1-font-size);
    font-weight: var(--font-bold);
    margin-bottom: 0.5rem;

}

.home_socials {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1.75rem;
    margin: 1.5rem 0;
}

.home_social-link {
    color: var(--title-color);
    font-size: 1.3rem;
    transition: .3s;
    /* width: 20px; */
}

.home_social-link:hover {
    color: var(--third-color);
}

.scroll_down {
    position: absolute;
    bottom: 5rem;
    left: 0;
    width: 100%;
}

.home_scroll-name {
    font-size: var(--small-font-size);
}

.mouse {
    border: 2px solid #454360;
    display: block;
    height: 1.6rem;
    width: 1.25rem;
    margin: auto;
    margin-top: .75rem;
    border-radius: 1rem;
    position: relative;
}

@keyframes ani-mouse {
    0% {
        top: 29%;
    }

    15% {
        top: 50%;
    }

    50% {
        top: 50%;
    }

    100% {
        top: 29%;
    }
}

.wheel {
    background-color: var(--title-color);
    border-radius: 100%;
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    top: .5rem;
    left: 50%;
    transform: translateX(-50%);
    animation: ani-mouse 2s linear infinite;
}


/* TODO */
/* .shapes {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.shape {
    position: absolute;
    width: 100px;
}

.s1 {
    left: 2%;
    top: 10%;
}

.s2 {
    left: 18%;
    top: 30%;
}

.s3 {
    left: 5%;
    top: 30%;
}

.s4 {
    left: 44%;
    top: 10%;
} */

@media screen and (max-width: 1024px) {
    .home_social-link {
        font-size: 1.125rem;
    }
}