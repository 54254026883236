.about_container {
    grid-template-columns: 3fr 9fr;
    column-gap: 1.875rem;
}

.about_data {
    padding: 1.875rem;
    background-color: var(--container-color);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.875rem;
    row-gap: 1rem;
    align-items: flex-start;
    position: relative;
}

.about_info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.about_description {
    margin-bottom: 1rem;
    text-align: justify;
}

.about_skills {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.icons_skills {
    font-size: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.container_download-resume {
    grid-column: 1/3;
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 1024px) {
    .about_container {
        grid-template-columns: 720px;
        justify-content: center;
        row-gap: 1.875rem;
    }

    .about_data::before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        left: 49%;
        top: -19px;
    }

    .about_img {
        justify-self: center;
    }

    .about_box {
        flex-direction: column;
        row-gap: .5rem;
        text-align: center;
    }
}

@media screen and (max-width: 700px) {
    .about_container {
        grid-template-columns: 350px;
    }

    .about_data::before {
        left: 47%;
    }

    .about_data {
        grid-template-columns: 1fr;
        row-gap: 1.875rem;
    }

}

@media screen and (max-width: 576px) {
    .about_container {
        grid-template-columns: 320px;
    }


    .container_download-resume {
        grid-column: unset;
    }
}

@media screen and (max-width: 350px) {
    .about_container {
        grid-template-columns: 1fr;
    }

    .container_download-resume {
        grid-column: unset;
    }
}